import { useLocation } from "react-router";
import avt from "../../assets/images/avatar/avata_profile.jpg";
import "./index.css";
import { Link } from "react-router-dom";

export const CollectionCard = (props) => {
  const location = useLocation();
  return (
    <div className="col-lg-4 col-md-6 col-12 collect">
      <div className="sc-card-collection style-2 home2">
        <div className="card-bottom cards">
          <div className="author">
            <div className="sc-author-box style-2">
              <div className="author-avatar owner-logo">
                <img
                  src={props.user?.image_url ? props.user?.image_url : avt}
                  alt=""
                  className="avatar"
                />
                <div className="badge">
                  <i className="ripple"></i>
                </div>
              </div>
            </div>
            <div className="content">
              <h4>
                <Link to={`/collection/${props.item?.name.toLowerCase()}`}>
                  {props.item?.name}
                </Link>
              </h4>
              <div className="infor">
                {location.pathname === "/collections/yours" ? (
                  <span>{props.item?.count} item products</span>
                ) : (
                  <div>
                    <span>Created by</span>
                    <span className="name">
                      <Link
                        to={`/collection/${props.item?.name.toLowerCase()}`}
                      >
                        {props.user?.name ? props.user?.name : "Unnamed"}
                      </Link>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Link to={`/collection/${props.item?.name.toLowerCase()}`}>
          <div className="media-images-collection">
            <img
              src={props.item?.image_url ? props.item?.image_url : avt}
              className="imges1"
              alt=""
            />
          </div>
        </Link>
      </div>
    </div>
  );
};
