import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Header, Footer, ModalDialog } from "../../components";
import "react-tabs/style/react-tabs.css";
import imageBox from "../../assets/images/box-item/image-box-6.jpg";
import avt from "../../assets/images/avatar/avt-9.jpg";
import "./index.css";
import ipfs from "../../ipfs";
import s3Client from "../../s3";
import axios from "axios";
import { useSelector } from "react-redux";
import { getIMXState, getUserState } from "../../redux";
import { TailSpin } from "react-loader-spinner";

export const CreateCollection = () => {
  const [logoContent, setLogoContent] = useState();
  const [logoBuffer, setLogoBuffer] = useState();
  const [collection_name, setCollectionName] = useState("Collection Name");
  const [collection_description, setCollectionDescription] =
    useState("Description");
  const [currentCategory, setCurrentCategory] = useState("Art");
  const { wallet_address } = useSelector(getIMXState);
  const { name, category, image_url } = useSelector(getUserState);
  const [isAvailable, setIsAvailable] = useState(true);
  const [modalDialogShow, setModalDialogShow] = useState(false);
  const [modalData, setModalData] = useState();
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API_ADDRESS}/collection/is-available/${collection_name}`
      )
      .then((response) => {
        if (response.data.state === "success") {
          setIsAvailable(true);
        } else {
          setIsAvailable(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [collection_name]);

  const captureFile = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    let reader_url = new window.FileReader();

    reader_url.readAsDataURL(file);
    reader_url.onloadend = () => {
      setLogoContent(reader_url.result);
    };

    let reader_array = new window.FileReader();

    reader_array.readAsArrayBuffer(file);
    reader_array.onloadend = () => {
      setLogoBuffer(reader_array.result);
    };
  };

  const createCollection = () => {
    if (loading) return;
    if (!isAvailable) return;
    if (!imageFile) return;
    setLoading(true);

    const buffer = Buffer.from(logoBuffer);
    ipfs
      .add(buffer)
      .then(async (ipfsHash) => {
        try {
          const { location } = await s3Client.uploadFile(imageFile);
          axios.post(
            `${process.env.REACT_APP_BACKEND_API_ADDRESS}/collection/create`,
            {
              name: collection_name,
              description: collection_description,
              category: currentCategory,
              ipfs_url: `${process.env.REACT_APP_IPFS_URL}/${ipfsHash.path}`,
              image_url: location,
              owner: wallet_address,
            }
          )
            .then((response) => {
              if (response.data.state === "success") {
                setModalDialogShow(true);
                setModalData({
                  ...modalData,
                  title: "Success",
                  description: "Collection Created",
                  url: `/collection/${response.data.data.name.toLowerCase()}`,
                });
                setLoading(false);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        catch (error) {
          console.log(error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="create-item">
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Create Collection</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="tf-create-item tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-3 col-lg-6 col-md-6 col-12">
              <h4 className="title-create-item">Preview Collection</h4>
              <div className="sc-card-product">
                <div className="card-media">
                  <Link to="#">
                    <img src={logoContent ? logoContent : imageBox} alt="" />
                  </Link>
                </div>
                <div className="card-title">
                  <h5>
                    <Link to="#">{collection_name}</Link>
                  </h5>
                </div>
                <div className="meta-info">
                  <div className="author">
                    <div className="avatar">
                      <img src={image_url ? image_url : avt} alt="" />
                    </div>
                    <div className="info">
                      <span>Owned By</span>
                      <h6>
                        {" "}
                        <Link to="#">{name ? name : "Unnamed"}</Link>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-6 col-md-12 col-12">
              <div className="form-create-item">
                <h4 className="title-create-item">Upload file</h4>
                <label className="uploadFile">
                  <span className="filename">
                    PNG, JPG, GIF, WEBP or MP4. Max 200mb.
                  </span>
                  <input
                    type="file"
                    className="inputfile form-control"
                    name="file"
                    onChange={(e) => captureFile(e)}
                  />
                </label>
                <div className="flat-tabs tab-create-item">
                  <h4 className="title-create-item">Collection Name</h4>
                  <input
                    type="text"
                    placeholder="Collection Name"
                    onChange={(e) => {
                      setCollectionName(e.target.value);
                    }}
                  />
                  {isAvailable ? "" : <h6>This name is not available</h6>}
                  <h4 className="title-create-item">Description</h4>
                  <textarea
                    placeholder="e.g. “This is very limited collection"
                    onChange={(e) => {
                      setCollectionDescription(e.target.value);
                    }}
                  ></textarea>

                  <h4 className="title-create-item">Category</h4>
                  <div className="inner-row-form style-2 col-xl-3 col-md-3 col-sm-6 pad-0">
                    <div className="seclect-box category">
                      <div id="item-create" className="dropdown selector">
                        <Link
                          to="#"
                          className="btn-selector nolink category-selector"
                        >
                          {currentCategory}
                        </Link>
                        <ul>
                          {category?.map((item, index) => {
                            return (
                              <li
                                key={index}
                                onClick={(e) =>
                                  setCurrentCategory(item.name)
                                }
                              >
                                <span>{item.name}</span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-content-center mt-9">
                    <Link
                      to="#"
                      className="sc-button style-1 relative"
                      onClick={createCollection}
                    >
                      <span>Create</span>
                      <div className={`${loading ? "d-flex" : ""} loader`}>
                        <TailSpin color="#FFFF00" height={20} width={20} />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalDialog
        show={modalDialogShow}
        onHide={() => setModalDialogShow(false)}
        title={modalData?.title}
        description={modalData?.description}
        url={modalData?.url}
        setModalDialogShow={setModalDialogShow}
      />
      <Footer />
    </div>
  );
};
