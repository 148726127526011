import React from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router";

export const DeleteModal = (props) => {
  const navigate = useNavigate();

  const deleteItem = () => {
    axios
      .get(props.url)
      .then((response) => {
        if (response.data.state === "success") {
          props.setModalDialogShow(false);
          navigate("/collections");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <div className="modal-body space-y-20 pd-40">
        <h3>{props.title}</h3>
        <div className="hr"></div>
        <div className="d-flex justify-content-between">
          <p> {props.description}</p>
        </div>
        <div className="d-flex align-items-center">
          <Link
            to="#"
            onClick={() => deleteItem()}
            className="btn btn-primary mr-2"
            data-toggle="modal"
            data-target="#popup_bid_success"
            data-dismiss="modal"
            aria-label="Close"
          >
            {" "}
            OK
          </Link>
          <Link
            to="#"
            onClick={() => props.setModalDialogShow(false)}
            className="btn btn-primary ml-2"
            data-toggle="modal"
            data-target="#popup_bid_success"
            data-dismiss="modal"
            aria-label="Close"
          >
            {" "}
            Cancel
          </Link>
        </div>
      </div>
    </Modal>
  );
};
