import React, { useEffect, useState } from "react";
import { Header, Footer, CardModal, ModalDialog } from "../../components";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import avt from "../../assets/images/avatar/avt-2.jpg";
import axios from "axios";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { getIMXState, getUserState } from "../../redux";
import { ethers } from "ethers";
import "./index.css";
import { ERC721TokenType } from "@imtbl/imx-sdk";

export const ExploreNFTDetail = () => {
  const [nft_data, setNFTData] = useState();
  const [collection_owner, setCollectionOwner] = useState();
  const [collection_creator, setCollectionCreator] = useState();
  const [history_item, setHistoryItem] = useState([]);
  const [favourite, setFavourite] = useState(false);
  const [favourite_count, setFavouriteCount] = useState(0);

  const params = useParams();
  const [price, setPrice] = useState(0);
  const { wallet_address, link, client } = useSelector(getIMXState);
  const { image_url, name, dollar } = useSelector(getUserState);

  const [modalShow, setModalShow] = useState(false);
  const [modalDialogShow, setModalDialogShow] = useState(false);
  const [modalData, setModalData] = useState();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API_ADDRESS}/asset/${params.collection_name}/${params.nft_name}`
      )
      .then((response) => {
        if (response.data.state === "success") {
          setNFTData(response.data.data);
          setCollectionOwner(response.data.owner);
          setCollectionCreator(response.data.creator);
          setPrice(response.data.data.price);
          setFavouriteCount(response.data.data.favourite_count);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [params.collection_name, params.nft_name]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API_ADDRESS}/favourite/${wallet_address}/${nft_data?.token_id}`
      )
      .then((response) => {
        if (response.data.state === "success") {
          if (response.data.data.length) {
            setFavourite(true);
          } else {
            setFavourite(false);
          }
        }
      });
  }, [wallet_address, nft_data]);

  useEffect(() => {
    if (nft_data) {
      client
        ?.getOrders({
          sell_token_address: process.env.REACT_APP_COLLECTION_ADDRESS,
          sell_token_id: nft_data.token_id,
          order_by: "created_at",
        })
        .then((orders) => {
          console.log(orders);
          const data = orders.result.filter(
            (value) => value.status === "filled"
          );

          axios
            .post(`${process.env.REACT_APP_BACKEND_API_ADDRESS}/history`, {
              data: data,
            })
            .then((response) => {
              if ((response.data.state = "success")) {
                setHistoryItem(response.data.data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [client, nft_data]);
  const saleOrBuy = () => {
    if (collection_owner?.wallet_address === wallet_address) {
      if (nft_data.selling) {
        client
          .getOrders({
            sell_token_address: process.env.REACT_APP_COLLECTION_ADDRESS,
            sell_token_id: nft_data.token_id,
            order_by: "created_at",
          })
          .then((orders) => {
            if (orders.result.length && orders.result[0].status === "active") {
              let last_price = 0;
              const last_order = orders.result.filter(
                (value) => value.status === "filled"
              );
              if (last_order.length) {
                last_price = ethers.utils.formatEther(
                  last_order[0]?.buy.data.quantity
                );
              } else {
                last_price = 0.0;
              }
              link
                .cancel({
                  orderId: orders.result[0].order_id,
                })
                .then((result) => {
                  setNFTData({
                    ...nft_data,
                    selling: false,
                    price: last_price,
                    owner: wallet_address,
                  });
                  axios
                    .put(
                      `${process.env.REACT_APP_BACKEND_API_ADDRESS}/asset/${params.collection_name}/${params.nft_name}/selling`,
                      {
                        selling: false,
                        price: last_price,
                        owner: wallet_address,
                      }
                    )
                    .then((response) => {
                      if (response.data.state === "success") {
                        console.log(response.data.data);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setModalShow(true);
      }
    } else {
      client
        .getOrders({
          sell_token_address: process.env.REACT_APP_COLLECTION_ADDRESS,
          sell_token_id: nft_data.token_id,
          order_by: "created_at",
        })
        .then((orders) => {
          if (orders.result.length && orders.result[0].status === "active") {
            const takerFees = [
              {
                percentage: 2.5,
                recipient: process.env.REACT_APP_MINTER_PUBLIC_KEY,
              },
            ];
            link
              .buy({
                orderIds: [orders.result[0].order_id],
                fees: takerFees,
              })
              .then((result) => {
                setNFTData({
                  ...nft_data,
                  selling: false,
                  price: price,
                  owner: wallet_address,
                });
                setCollectionOwner({
                  ...collection_owner,
                  wallet_address: wallet_address,
                  name: name,
                  image_url: image_url,
                });
                axios
                  .put(
                    `${process.env.REACT_APP_BACKEND_API_ADDRESS}/asset/${params.collection_name}/${params.nft_name}/selling`,
                    {
                      selling: false,
                      price: price,
                      owner: wallet_address,
                      type: "buy",
                    }
                  )
                  .then((response) => {
                    if (response.data.state === "success") {
                      console.log(response.data.data);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const addOrRemoveFavourite = () => {
    if (favourite) {
      axios
        .post(`${process.env.REACT_APP_BACKEND_API_ADDRESS}/favourite/remove`, {
          wallet_address: wallet_address,
          token_id: nft_data.token_id,
        })
        .then((response) => {
          if (response.data.state === "success") {
            setFavourite(false);
            setFavouriteCount(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_BACKEND_API_ADDRESS}/favourite/add`, {
          wallet_address: wallet_address,
          token_id: nft_data.token_id,
        })
        .then((response) => {
          if (response.data.state === "success") {
            setFavourite(true);
            setFavouriteCount(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const deleteitem = () => {
    link
      .transfer([
        {
          type: ERC721TokenType.ERC721,
          tokenId: nft_data?.token_id,
          tokenAddress: process.env.REACT_APP_COLLECTION_ADDRESS,
          toAddress: "0x0000000000000000000000000000000000000000",
          amount: "1",
        },
      ])
      .then((result) => {
        axios
          .delete(
            `${process.env.REACT_APP_BACKEND_API_ADDRESS}/delete/${nft_data?.token_id}`
          )
          .then((response) => {
            if (response.data.state === "success") {
              setModalDialogShow(true);
              setModalData({
                ...modalData,
                title: "Success",
                description: "NFT Item Deleted",
                url: `/collections`,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="item-details">
      <Header />
      <div className="tf-section tf-item-details mb-50">
        <div className="themesflat-container mt-30">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="content-left tags">
                <div className="media tag">
                  <img
                    src={nft_data?.image_url}
                    className="detail-logo"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="content-right">
                <div className="sc-item-details">
                  <h2 className="style2">“ {nft_data?.name} ”</h2>
                  <div className="meta-item">
                    <div className="left">
                      <span className="viewed eye">{nft_data?.view_count}</span>
                      <span
                        to="#"
                        className={`liked heart wishlist-button mg-l-8 ${
                          favourite ? "active" : ""
                        }`}
                        onClick={addOrRemoveFavourite}
                      >
                        <span className="number-like">{favourite_count}</span>
                      </span>
                    </div>
                  </div>
                  <div className="client-infor sc-card-product">
                    <div className="meta-info">
                      <div className="author">
                        <div className="avatar">
                          <img
                            src={
                              collection_owner?.image_url
                                ? collection_owner?.image_url
                                : avt
                            }
                            className="owner-avatar"
                            alt=""
                          />
                        </div>
                        <div className="info">
                          <span>Owned By</span>
                          <h6>
                            {" "}
                            <Link to="#">
                              {collection_owner?.name
                                ? collection_owner?.name
                                : "Unnamed"}
                            </Link>{" "}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="meta-info">
                      <div className="author">
                        <div className="avatar">
                          <img
                            src={
                              collection_creator?.image_url
                                ? collection_creator?.image_url
                                : avt
                            }
                            className="owner-avatar"
                            alt=""
                          />
                        </div>
                        <div className="info">
                          <span>Create By</span>
                          <h6>
                            {" "}
                            <Link to="#">
                              {collection_creator?.name
                                ? collection_creator?.name
                                : "Unnamed"}
                            </Link>{" "}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p>{nft_data?.description}</p>
                  <div className="meta-item-details style2">
                    <div className="item meta-price">
                      <span className="heading">Current Price</span>
                      <div className="price">
                        <div className="price-box">
                          <h5> {nft_data?.price} ETH</h5>
                          <span>
                            = ${(nft_data?.price * dollar).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full row">
                    <div className="col-6">
                      <Link
                        to="#"
                        className="sc-button loadmore style bag fl-button pri-3"
                        onClick={saleOrBuy}
                      >
                        <span>
                          {collection_owner?.wallet_address === wallet_address
                            ? nft_data?.selling
                              ? "Cancelling sale"
                              : "List for sale"
                            : nft_data?.selling
                            ? "Buy Now"
                            : "Not Buy"}
                        </span>
                      </Link>
                    </div>
                    {collection_owner?.wallet_address === wallet_address &&
                    nft_data?.owner === nft_data?.creator ? (
                      <div className="col-6">
                        <Link
                          to="#"
                          className="sc-button loadmore style bag fl-button pri-3 warning"
                          onClick={deleteitem}
                        >
                          <span>Delete Item</span>
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flat-tabs themesflat-tabs">
                    <Tabs>
                      <TabList>
                        <Tab>Bid History</Tab>
                      </TabList>

                      <TabPanel>
                        <ul className="bid-history-list">
                          {history_item.map((item, index) => (
                            <li key={index} item={item}>
                              <div className="content">
                                <div className="client">
                                  <div className="sc-author-box style-2">
                                    <div className="author-avatar">
                                      <Link to="#">
                                        <img
                                          src={
                                            item.image_url
                                              ? item.image_url
                                              : avt
                                          }
                                          className="avatar"
                                          alt=""
                                        />
                                      </Link>
                                      <div className="badge"></div>
                                    </div>
                                    <div className="author-infor">
                                      <div className="name">
                                        <h6>
                                          <Link to="#">
                                            {item.owner_name
                                              ? item.owner_name
                                              : "Unnamed"}{" "}
                                          </Link>
                                        </h6>{" "}
                                        <span> sell this</span>
                                      </div>
                                      <span className="time">{item.time}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="price">
                                  <h5>
                                    {ethers.utils.formatEther(
                                      item?.buy.data.quantity
                                    )}{" "}
                                    ETH
                                  </h5>
                                  <span>
                                    = $
                                    {parseFloat(
                                      ethers.utils.formatEther(
                                        item?.buy.data.quantity
                                      )
                                    ) * dollar}
                                  </span>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CardModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        price={price}
        setPrice={setPrice}
        token_id={nft_data?.token_id}
        setNFTData={setNFTData}
        collection_name={params.collection_name}
        nft_name={params.nft_name}
        nft_data={nft_data}
        setShow={setModalShow}
      />
      <ModalDialog
        show={modalDialogShow}
        onHide={() => setModalDialogShow(false)}
        title={modalData?.title}
        description={modalData?.description}
        setModalDialogShow={setModalDialogShow}
        url={modalData?.url}
      />
      <Footer />
    </div>
  );
};
