import { useSelector } from "react-redux";
import avt from "../../assets/images/avatar/avata_profile.jpg";
import { getUserState } from "../../redux";
import { Link } from "react-router-dom";
import "./index.css";

export const NFTCard = (props) => {
  const { dollar } = useSelector(getUserState);
  return (
    <div className="col-xl-3 col-lg-4 col-md-6 col-12">
      <div className="sc-card-product explode ">
        <div className="card-media">
          <Link
            to={`/asset/${props.item?.collections.toLowerCase()}/${props.item?.name.toLowerCase()}`}
          >
            <img
              src={props.item?.image_url}
              className="nft-source"
              alt=""
            />
          </Link>
        </div>
        <div className="card-title mg-bt-16">
          <h5>
            <Link
              to={`/asset/${props.item?.collections.toLowerCase()}/${props.item?.name.toLowerCase()}`}
            >
              {props.item?.name}
            </Link>
          </h5>
        </div>
        <div className="meta-info">
          <div className="author">
            <div className="avatar">
              <img
                src={props.user?.image_url ? props.user?.image_url : avt}
                alt=""
              />
            </div>
            <div className="info">
              <span>Creator</span>
              <h6>
                {" "}
                <Link
                  to={`/asset/${props.item?.collections.toLowerCase()}/${props.item?.name.toLowerCase()}`}
                >
                  {props.user?.name ? props.user?.name : "Unnamed"}
                </Link>{" "}
              </h6>
            </div>
          </div>
          <div className="tags">ETH</div>
        </div>
        <div className="card-bottom style-explode">
          <div className="price">
            <span>Current Price</span>
            <div className="price-details">
              <h5>{props.item?.price} ETH</h5>
              <span>
                = ${parseFloat(dollar * props.item?.price).toFixed(2)}
              </span>
            </div>
          </div>
          <Link
            to={`/asset/${props.item?.collections.toLowerCase()}/${props.item?.name.toLowerCase()}`}
            className="view-history reload"
          >
            View Detail
          </Link>
        </div>
      </div>
    </div>
  );
};
