import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Header, Footer } from "../../components";
import img1 from "../../assets/images/icon/connect-1.png";
import img2 from "../../assets/images/icon/connect-2.png";
import { useDispatch, useSelector } from "react-redux";
import { getIMXState, setStarkPublicKey, setWalletAddress } from "../../redux";
import { ProviderPreference } from "@imtbl/imx-sdk";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { setImageUrl, setName } from "../../redux/user";

export const WalletConnect = () => {
  const { link } = useSelector(getIMXState);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const [data] = useState([
    {
      img: img1,
      title: "Meta Mask",
      provider: ProviderPreference.METAMASK,
    },
    {
      img: img2,
      title: "Magic Link",
      provider: ProviderPreference.MAGIC_LINK,
    },
  ]);
  const connectWallet = (provider) => {
    link
      .setup({
        providerPreference: provider,
      })
      .then((value) => {
        axios
          .post(`${process.env.REACT_APP_BACKEND_API_ADDRESS}/login`, {
            wallet_address: value.address,
          })
          .then((response) => {
            if (response.data.state === "success") {
              localStorage.setItem(
                process.env.REACT_APP_WALLET_ADDRESS,
                value.address
              );
              localStorage.setItem(
                process.env.REACT_APP_STARK_PUBLIC_KEY,
                value.starkPublicKey
              );

              dispatch(setWalletAddress(value.address));
              dispatch(setStarkPublicKey(value.starkPublicKey));

              dispatch(setName(response.data.data.name));
              dispatch(setImageUrl(response.data.data.image_url));

              navigate(
                location.search.split("=").length === 2
                  ? location.hash.split("=")[1]
                  : "/edit-profile"
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
  };

  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Connect Wallet</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="tf-connect-wallet tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="sc-box-icon-inner style-2">
                {data.map((item, index) => (
                  <div key={index} className="sc-box-icon">
                    <div className="img">
                      <img src={item.img} alt=""/>
                    </div>
                    <h4 className="heading">
                      <Link
                        to={location.search}
                        onClick={() => connectWallet(item.provider)}
                      >
                        {item.title}
                      </Link>{" "}
                    </h4>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
