import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { getIMXState } from "../redux";

export const CheckLogin = ({ children }) => {
  const location = useLocation();
  const {
    wallet_address
  } = useSelector(getIMXState);

  if (location.pathname === "/") {
    return children;
  } else if (location.pathname === "/wallet-connect") {
    if (wallet_address) {
      if (location.search === "#" || location.search === "") {
        return <Navigate to={`/`} />
      } else {
        return <Navigate to={`${location.search.split('=')[1]}`} />
      }
    } else {
      return children;
    }
  } else {
    if (wallet_address) {
      return children;
    } else {
      return <Navigate to={`/wallet-connect?path=${location.pathname}`} />
    }
  }
}