import React from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getIMXState } from "../../redux";
import axios from "axios";

export const CardModal = (props) => {
  const { link, wallet_address } = useSelector(getIMXState);
  const sale = () => {
    link
      .sell({
        tokenId: props.token_id,
        tokenAddress: process.env.REACT_APP_COLLECTION_ADDRESS,
        amount: parseFloat(props.price),
      })
      .then((result) => {
        props.setNFTData({
          ...props.nft_data,
          selling: true,
          price: props.price,
          owner: wallet_address,
        });
        axios
          .put(
            `${process.env.REACT_APP_BACKEND_API_ADDRESS}/asset/${props.collection_name}/${props.nft_name}/selling`,
            {
              selling: true,
              price: props.price,
              owner: wallet_address,
            }
          )
          .then((response) => {
            if (response.data.state === "success") {
              props.setShow(false);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body space-y-20 pd-40">
        <h3>List for sale item</h3>
        <input
          type="text"
          className="form-control"
          placeholder="00.00 ETH"
          onChange={(e) => props.setPrice(parseFloat(e.target.value))}
        />
        <div className="hr"></div>
        <div className="d-flex justify-content-between">
          <p> Buyer must pay at least:</p>
          <p className="text-right price color-popup">
            {" "}
            {(props.price * (props.nft_data?.royalty + 2.5 + 100)) /
              100} ETH{" "}
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p> Service free:</p>
          <p className="text-right price color-popup">
            {" "}
            {(props.price * 2.5) / 100} ETH{" "}
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p> Minter free:</p>
          <p className="text-right price color-popup">
            {" "}
            {(props.price * props.nft_data?.royalty) / 100} ETH{" "}
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p> Total price amount:</p>
          <p className="text-right price color-popup"> {props.price} ETH </p>
        </div>
        <Link
          to="#"
          onClick={sale}
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#popup_bid_success"
          data-dismiss="modal"
          aria-label="Close"
        >
          {" "}
          List for sale
        </Link>
      </div>
    </Modal>
  );
};
