import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Header, Footer, ModalDialog } from "../../components";
import avt from "../../assets/images/avatar/avata_profile.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getIMXState } from "../../redux";
import axios from "axios";
import s3Client from "../../s3";
import "./index.css";
import { setImageUrl, setName, setAmount } from "../../redux/user";
import { TailSpin } from "react-loader-spinner";

export const EditProfile = () => {
  const { wallet_address } = useSelector(getIMXState);
  const name = useRef();
  const email = useRef();
  const [logoContent, setLogoContent] = useState();
  const [modalDialogShow, setModalDialogShow] = useState(false);
  const [modalData, setModalData] = useState();
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    if (wallet_address) {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_API_ADDRESS}/user/${wallet_address}`
        )
        .then((response) => {
          if (response.data.state === "success") {
            setLogoContent(response.data.data?.image_url);
            dispatch(setName(response.data.data?.name));
            dispatch(setImageUrl(response.data.data?.image_url));
            dispatch(setAmount(response.data.data?.amount));
            name.current.value = response.data.data?.name
              ? response.data.data?.name
              : "";
            email.current.value = response.data.data?.email
              ? response.data.data?.email
              : "";
          }
        });
    }
  }, [dispatch, wallet_address]);

  const captureFile = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    let reader_url = new window.FileReader();

    reader_url.readAsDataURL(file);
    reader_url.onloadend = () => {
      setLogoContent(reader_url.result);
    };
  };

  const update = async () => {
    setLoading(true);
    if (!imageFile) {
      axios.post(
        `${process.env.REACT_APP_BACKEND_API_ADDRESS}/user/${wallet_address}/update`,
        {
          name: name.current.value,
          email: email.current.value
        }
      )
        .then((response) => {
          if (response.data.state === "success") {
            dispatch(setName(name.current.value));
            setModalDialogShow(true);
            setModalData({
              ...modalData,
              title: "Success",
              description: "Your Profile Updated",
              url: `/my-items`,
            });
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      try {
        const { location } = await s3Client.uploadFile(imageFile);
        axios
        .post(
          `${process.env.REACT_APP_BACKEND_API_ADDRESS}/user/${wallet_address}/update`,
          {
            name: name.current.value,
            email: email.current.value,
            image_url: location,
          }
        )
        .then((response) => {
          if (response.data.state === "success") {
            dispatch(setName(name.current.value));
            dispatch(setImageUrl(location));
            setModalDialogShow(true);
            setModalData({
              ...modalData,
              title: "Success",
              description: "Your Profile Updated",
              url: `/my-items`,
            });
            setLoading(false);
          }
        })
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };
  return (
    <div>
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Edit Profile</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="tf-create-item tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 col-12">
              <div className="sc-card-profile text-center">
                <div className="card-media">
                  <img
                    id="profileimg"
                    src={logoContent ? logoContent : avt}
                    className="logo-imges"
                    alt=""
                  />
                </div>
                <div id="upload-profile">
                  <Link to="#" className="btn-upload">
                    Upload New Photo{" "}
                  </Link>
                  <input
                    id="tf-upload-img"
                    type="file"
                    name="profile"
                    required
                    onChange={(e) => captureFile(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-8 col-md-12 col-12">
              <div className="form-upload-profile">
                <div action="#" className="form-profile">
                  <div className="form-infor-profile">
                    <div className="info-account">
                      <h4 className="title-create-item">Account info</h4>
                      <div>
                        <h4 className="title-infor-account">Display name</h4>
                        <input
                          type="text"
                          placeholder="Trista Francis"
                          ref={name}
                        />
                      </div>
                      <div className="mt-30">
                        <h4 className="title-infor-account">Email</h4>
                        <input
                          type="email"
                          placeholder="Enter your email"
                          ref={email}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-30 mg-t-15 d-flex inline">
                    <button
                      className="tf-button-submit"
                      type="button"
                      onClick={update}
                    >
                      Update Profile
                    </button>
                    <div className={`${loading ? "d-flex" : ""} loader`}>
                      <TailSpin color="#FFFF00" height={20} width={20} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalDialog
        show={modalDialogShow}
        onHide={() => setModalDialogShow(false)}
        title={modalData?.title}
        description={modalData?.description}
        url={modalData?.url}
        setModalDialogShow={setModalDialogShow}
      />
      <Footer />
    </div>
  );
};
