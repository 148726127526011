import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getIMXState } from "../../redux";
import { ETHTokenType } from "@imtbl/imx-sdk";
import { ModalDialog } from "..";

export const DepositModal = (props) => {
  const { link } = useSelector(getIMXState);
  const [amount, setAmount] = useState(0.0);
  const [modalDialogShow, setModalDialogShow] = useState(false);
  const [modalData, setModalData] = useState();
  const deposit = () => {
    link
      .deposit({
        type: ETHTokenType.ETH,
        amount: amount,
      })
      .then((result) => {
        setModalDialogShow(true);
        setModalData({
          ...modalData,
          title: "Success",
          description: "You can check after a few minutes",
          url: `#`,
        });
        props.setModalShow(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body space-y-20 pd-40">
        <h3>Deposit Crypto</h3>
        <input
          type="text"
          className="form-control"
          placeholder="00.00 ETH"
          onChange={(e) => setAmount(parseFloat(e.target.value))}
        />
        <div className="hr"></div>
        <div className="d-flex justify-content-between">
          <p> After a few minutes, you'll see the deposit result!</p>
        </div>
        <Link
          to="#"
          onClick={deposit}
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#popup_bid_success"
          data-dismiss="modal"
          aria-label="Close"
        >
          {" "}
          Deposit
        </Link>
      </div>
      <ModalDialog
        show={modalDialogShow}
        onHide={() => setModalDialogShow(false)}
        setModalDialogShow={setModalDialogShow}
        title={modalData?.title}
        description={modalData?.description}
        url={modalData?.url}
      />
    </Modal>
  );
};
