import React, { useEffect, useState } from "react";
import {
  Header,
  Footer,
  TodayPicks,
  PopularCollection,
  Slider,
  Create,
} from "../../components";
import heroSliderData from "../../assets/fake-data/data-slider";
// import TopSeller from "../../components/layouts/TopSeller";
import axios from "axios";

export const Home = () => {
  const [topItem, setTopItem] = useState();
  // const [topSeller, setTopSeller] = useState([]);
  const [todayPicks, setTodayPicks] = useState([]);
  const [todayUsers, setTodayUsers] = useState([]);
  const [popular, setPopular] = useState([]);
  const [popularUsers, setPopularUsers] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_API_ADDRESS}/find/topItem`)
      .then((response) => {
        if (response.data.state === "success") {
          setTopItem(response.data.data);
        }
      });

    // axios
    //   .get(`${process.env.REACT_APP_BACKEND_API_ADDRESS}/find/topSeller`)
    //   .then((response) => {
    //     if (response.data.state === "success") {
    //       setTopSeller(response.data.data);
    //     }
    //   });

    axios
      .get(`${process.env.REACT_APP_BACKEND_API_ADDRESS}/find/todayPicks`)
      .then((response) => {
        if (response.data.state === "success") {
          setTodayPicks(response.data.data);
          setTodayUsers(response.data.users);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_BACKEND_API_ADDRESS}/find/topCollection`)
      .then((response) => {
        if (response.data.state === "success") {
          setPopular(response.data.data);
          setPopularUsers(response.data.users);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="home-1">
      <Header />
      <Slider data={heroSliderData} topItem={topItem} />
      {/* <TopSeller data={topSeller} /> */}
      <TodayPicks data={todayPicks} users={todayUsers} />
      <PopularCollection data={popular} users={popularUsers} />
      <Create />
      <Footer />
    </div>
  );
};
