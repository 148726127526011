import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { NFTCard } from "..";

export const TodayPicks = (props) => {
  const data = props.data;
  const users = props.users;

  const [visible, setVisible] = useState(8);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 4);
  };
  return (
    <Fragment>
      <section className="tf-section today-pick">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="heading-live-auctions mg-bt-21">
                <h2 className="tf-title pad-l-7">Today's Picks</h2>
              </div>
            </div>
            {data.slice(0, visible).map((item, index) => (
              <NFTCard key={index} item={item} user={users[index]} />
            ))}
            {visible < data.length && (
              <div className="col-md-12 wrap-inner load-more text-center">
                <Link
                  to="#"
                  id="load-more"
                  className="sc-button loadmore fl-button pri-3"
                  onClick={showMoreItems}
                >
                  <span>Load More</span>
                </Link>
              </div>
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};
