import {
  Home,
  WalletConnect,
  ExploreCollections,
  CreateCollection,
  EditCollection,
  ExploreNFT,
  CreateNFT,
  ExploreNFTDetail,
  EditProfile,
  ExploreMyItems,
  ExploreFavourite,
  ExploreMyCollections
} from "../pages";
// import Home08 from "../pages/Home08";
// import Explore01 from "../pages/Explore01";
// import Explore02 from "../pages/Explore02";
// import Explore03 from "../pages/Explore03";
// import Explore04 from "../pages/Explore04";
// import LiveAuctions from "../pages/LiveAuctions";
// import ItemDetails01 from "../pages/ItemDetails01";
// import ItemDetails02 from "../pages/ItemDetails02";
// import Activity01 from "../pages/Activity01";
// import Activity02 from "../pages/Activity02";
// import Blog from "../pages/Blog";
// import BlogDetails from "../pages/BlogDetails";
// import HelpCenter from "../pages/HelpCenter";
// // import Authors01 from "../pages/Authors01";
// // import Authors02 from "../pages/Authors02";
// import CreateItem from "../pages/CreateItem";
// // import EditProfile from "../pages/EditProfile";
// import Ranking from "../pages/Ranking";
// import Login from "../pages/Login";
// import SignUp from "../pages/SignUp";
// import NoResult from "../pages/NoResult";
// import FAQ from "../pages/FAQ";
// import Contact01 from "../pages/Contact01";
// import Contact02 from "../pages/Contact02";

const routes = [
  { path: '/', component: <Home /> },
  { path: '/wallet-connect', component: <WalletConnect /> },
  { path: '/collections', component: <ExploreCollections /> },
  { path: '/collections/yours', component: <ExploreMyCollections /> },
  { path: '/collection/create', component: <CreateCollection /> },
  { path: '/collection/edit/:collection_name', component: <EditCollection /> },
  { path: '/collection/:collection_name', component: <ExploreNFT /> },
  { path: '/asset/create', component: <CreateNFT /> },
  { path: '/asset/create/:collection_name', component: <CreateNFT /> },
  { path: '/asset/:collection_name/:nft_name', component: <ExploreNFTDetail /> },
  { path: '/edit-profile', component: <EditProfile /> },
  { path: '/my-items', component: <ExploreMyItems /> },
  { path: '/my-favourite', component: <ExploreFavourite /> },
  // { path: '/', component: <Home01 /> },
  // { path: '/home-08', component: <Home08 /> },
  // { path: '/explore-01', component: <Explore01 /> },
  // { path: '/explore-02', component: <Explore02 /> },
  // { path: '/explore-03', component: <Explore03 /> },
  // { path: '/explore-04', component: <Explore04 /> },
  // { path: '/live-auctions', component: <LiveAuctions /> },
  // { path: '/item-details-01', component: <ItemDetails01 /> },
  // { path: '/item-details-02', component: <ItemDetails02 /> },
  // { path: '/activity-01', component: <Activity01 /> },
  // { path: '/activity-02', component: <Activity02 /> },
  // { path: '/blog', component: <Blog /> },
  // { path: '/blog-details', component: <BlogDetails /> },
  // { path: '/help-center', component: <HelpCenter /> },
  // { path: '/create-item', component: <CreateItem /> },
  // { path: '/ranking', component: <Ranking /> },
  // { path: '/login', component: <Login /> },
  // { path: '/sign-up', component: <SignUp /> },
  // { path: '/no-result', component: <NoResult /> },
  // { path: '/faq', component: <FAQ /> },
  // { path: '/contact-01', component: <Contact01 /> },
  // { path: '/contact-02', component: <Contact02 /> },
]

export default routes;