import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Header, Footer, ModalDialog } from "../../components";
import "react-tabs/style/react-tabs.css";
import img1 from "../../assets/images/box-item/image-box-6.jpg";
import avt from "../../assets/images/avatar/avt-9.jpg";
import "./index.css";
import ipfs from "../../ipfs";
import s3Client from "../../s3";
import axios from "axios";
import { useSelector } from "react-redux";
import { getIMXState, getUserState } from "../../redux";
import { useParams } from "react-router";
import { TailSpin } from "react-loader-spinner";

export const CreateNFT = () => {
  const [logoContent, setLogoContent] = useState();
  const [logoBuffer, setLogoBuffer] = useState();
  const [nft_name, setNFTName] = useState("NFT Name");
  const [nft_description, setNFTDescription] = useState("Description");
  const [royalty, setRoyalty] = useState("2.5");
  const [currentCollection, setCurrentCollection] =
    useState("Select Collection");
  const { client, wallet_address } = useSelector(getIMXState);
  const { name, image_url } = useSelector(getUserState);
  const [collection, setCollection] = useState([]);
  const [modalDialogShow, setModalDialogShow] = useState(false);
  const [modalData, setModalData] = useState();
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState();
  const params = useParams();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API_ADDRESS}/collections/${wallet_address}`
      )
      .then((response) => {
        if (response.data.state === "success") {
          setCollection(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [wallet_address]);

  const captureFile = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    let reader_url = new window.FileReader();

    reader_url.readAsDataURL(file);
    reader_url.onloadend = () => {
      setLogoContent(reader_url.result);
    };

    let reader_array = new window.FileReader();

    reader_array.readAsArrayBuffer(file);
    reader_array.onloadend = () => {
      setLogoBuffer(reader_array.result);
    };
  };

  const createNFT = () => {
    if (loading) return;
    if (!imageFile) return;
    if (currentCollection === "Select Collection" && !params.collection_name) {
      setModalDialogShow(true);
      setModalData({
        ...modalData,
        title: "Error",
        description: "Please select collection",
        url: `#`,
      });
      return;
    }
    setLoading(true);
    const buffer = Buffer.from(logoBuffer);
    ipfs
      .add(buffer)
      .then(async (ipfsHash) => {
        const current = new Date();
        const tokenId = current.getTime().toString();
        client
          ?.mintV2([
            {
              users: [
                {
                  etherKey: wallet_address.toLowerCase(),
                  tokens: [
                    {
                      id: tokenId,
                      blueprint: tokenId,
                    },
                  ],
                },
              ],
              royalties: [
                {
                  recipient: wallet_address.toLowerCase(),
                  percentage: parseFloat(royalty),
                },
              ],
              contractAddress: process.env.REACT_APP_COLLECTION_ADDRESS,
            },
          ])
          .then(async (result) => {
            const selectCollection = collection.filter(
              (value) => value.name.toLowerCase() === params.collection_name
            );
            const { location } = await s3Client.uploadFile(imageFile);
            axios.post(
              `${process.env.REACT_APP_BACKEND_API_ADDRESS}/asset/create`,
              {
                name: nft_name,
                description: nft_description,
                royalty: parseFloat(royalty),
                collection: params.collection_name
                  ? selectCollection[0].name
                  : currentCollection,
                ipfs_url: `${process.env.REACT_APP_IPFS_URL}/${ipfsHash.path}`,
                image_url: location,
                owner: wallet_address,
                token_id: tokenId,
                creator: wallet_address,
              }
            )
              .then((response) => {
                if (response.data.state === "success") {
                  setModalDialogShow(true);
                  setModalData({
                    ...modalData,
                    title: "Success",
                    description: "New NFT Item Created",
                    url: `/asset/${response.data.data.collections.toLowerCase()}/${response.data.data.name.toLowerCase()}`,
                  });
                  setLoading(false);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="create-item">
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">Create NFT</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="tf-create-item tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-3 col-lg-6 col-md-6 col-12">
              <h4 className="title-create-item">Preview NFT</h4>
              <div className="sc-card-product">
                <div className="card-media">
                  <Link to="#">
                    <img src={logoContent ? logoContent : img1} alt="" />
                  </Link>
                </div>
                <div className="card-title">
                  <h5>
                    <Link to="#">{nft_name}</Link>
                  </h5>
                  <div className="tags">eth</div>
                </div>
                <div className="meta-info">
                  <div className="author">
                    <div className="avatar">
                      <img src={image_url ? image_url : avt} alt="" />
                    </div>
                    <div className="info">
                      <span>Owned By</span>
                      <h6>
                        {" "}
                        <Link to="#">{name ? name : "Unnamed"}</Link>
                      </h6>
                    </div>
                  </div>
                  <div className="price">
                    <span>Current Price</span>
                    <h5> 0 ETH</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-6 col-md-12 col-12">
              <div className="form-create-item">
                <h4 className="title-create-item">Upload file</h4>
                <label className="uploadFile">
                  <span className="filename">
                    PNG, JPG, GIF, WEBP or MP4. Max 200mb.
                  </span>
                  <input
                    type="file"
                    className="inputfile form-control"
                    name="file"
                    onChange={(e) => captureFile(e)}
                  />
                </label>
                <div className="flat-tabs tab-create-item">
                  <h4 className="title-create-item">NFT Name</h4>
                  <input
                    type="text"
                    placeholder="NFT Name"
                    onChange={(e) => {
                      setNFTName(e.target.value);
                    }}
                  />

                  <h4 className="title-create-item">Description</h4>
                  <textarea
                    placeholder="e.g. “This is very limited NFT"
                    onChange={(e) => {
                      setNFTDescription(e.target.value);
                    }}
                  ></textarea>

                  <div className="row-form flex">
                    <div className="inner-row-form col-xl-4 col-sm-6 p-0">
                      <h4 className="title-create-item">Royalties</h4>
                      <input
                        type="text"
                        placeholder={`${royalty} %`}
                        onChange={(e) => {
                          setRoyalty(e.target.value);
                        }}
                      />
                    </div>
                    <div className="inner-row-form style-2 col-xl-4 col-sm-6">
                      <h4 className="title-create-item">Collection</h4>
                      {params.collection_name ? (
                        <input
                          type="text"
                          placeholder={
                            collection.filter(
                              (value) =>
                                value?.name.toLowerCase() ===
                                params.collection_name.toLowerCase()
                            )[0]?.name
                          }
                          disabled
                        />
                      ) : (
                        <div className="seclect-box category">
                          <div id="item-create" className="dropdown selector">
                            <Link
                              to="#"
                              className="btn-selector nolink category-selector"
                            >
                              {currentCollection}
                            </Link>
                            <ul>
                              {collection?.map((item, index) => {
                                return (
                                  <li
                                    key={index}
                                    onClick={(e) =>
                                      setCurrentCollection(item.name)
                                    }
                                  >
                                    <span>{item.name}</span>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-content-center">
                    <Link
                      to="#"
                      className="sc-button style-1 relative"
                      onClick={createNFT}
                    >
                      <span>Create</span>
                      <div className={`${loading ? "d-flex" : ""} loader`}>
                        <TailSpin color="#FFFF00" height={20} width={20} />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalDialog
        show={modalDialogShow}
        onHide={() => setModalDialogShow(false)}
        title={modalData?.title}
        description={modalData?.description}
        setModalDialogShow={setModalDialogShow}
        url={modalData?.url}
      />
      <Footer />
    </div>
  );
};
