import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getIMXState } from "../../redux";
import { ETHTokenType } from "@imtbl/imx-sdk";
import { ethers } from "ethers";
import "./index.css";
import { ModalDialog } from "..";

export const WithdrawalModal = (props) => {
  const [modalDialogShow, setModalDialogShow] = useState(false);
  const [modalData, setModalData] = useState();
  const { link, client, wallet_address } = useSelector(getIMXState);
  const [amount, setAmount] = useState(0.0);
  const [preparingAmount, setPreparingAmount] = useState(0.0);
  const [withdrawalAmount, setWithdrawalAmount] = useState(0.0);
  const preparing = () => {
    link
      .prepareWithdrawal({
        type: ETHTokenType.ETH,
        amount: amount,
      })
      .then((result) => {
        setModalDialogShow(true);
        setModalData({
          ...modalData,
          title: "Success",
          description: "You can check after a few minutes",
          url: `#`,
        });
        props.setWithdrawModalShow(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const complete = () => {
    link
      .completeWithdrawal({
        type: ETHTokenType.ETH,
      })
      .then((result) => {
        setModalDialogShow(true);
        setModalData({
          ...modalData,
          title: "Success",
          description: "You can check after a few minutes",
          url: `#`,
        });
        props.setWithdrawModalShow(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (wallet_address) {
      client
        ?.listBalances({ user: wallet_address })
        .then((balances) => {
          setPreparingAmount(
            ethers.utils.formatEther(balances.result[0].preparing_withdrawal)
          );
          setWithdrawalAmount(
            ethers.utils.formatEther(balances.result[0].withdrawable)
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [client, wallet_address]);

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body space-y-20 pd-40">
        <h3>Withdraw Crypto</h3>
        <input
          type="text"
          className="form-control"
          placeholder="00.00 ETH"
          onChange={(e) => setAmount(parseFloat(e.target.value))}
        />
        <div className="hr"></div>
        <div className="d-flex justify-content-between">
          <p> Preparing Withdrawal:</p>
          <p className="text-right price color-popup">
            {" "}
            {preparingAmount} ETH{" "}
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p> Withdrawable Amount:</p>
          <p className="text-right price color-popup">
            {" "}
            {withdrawalAmount} ETH{" "}
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p> After a few minutes, you'll see the withdraw result!</p>
        </div>
        <div className="d-flex justify-content-around">
          <Link
            to="#"
            onClick={preparing}
            className="btn btn-primary mr-20"
            data-toggle="modal"
            data-target="#popup_bid_success"
            data-dismiss="modal"
            aria-label="Close"
          >
            {" "}
            Preparing Withdrawal
          </Link>
          <Link
            to="#"
            onClick={complete}
            className="btn btn-primary ml-20"
            data-toggle="modal"
            data-target="#popup_bid_success"
            data-dismiss="modal"
            aria-label="Close"
          >
            {" "}
            Complete Withdrawal
          </Link>
        </div>
      </div>
      <ModalDialog
        show={modalDialogShow}
        onHide={() => setModalDialogShow(false)}
        title={modalData?.title}
        description={modalData?.description}
        setModalDialogShow={setModalDialogShow}
        url={modalData?.url}
      />
    </Modal>
  );
};
