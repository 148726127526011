import './App.css';
import { Routes, Route } from 'react-router-dom';
import routes from './routes'
import { ImmutableXClient, Link } from '@imtbl/imx-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { AlchemyProvider } from '@ethersproject/providers';
import { Wallet } from '@ethersproject/wallet';
import { useEffect } from 'react';
import { getIMXState, setClient, setLink } from './redux';
import { CheckLogin } from './routes/checklogin';
import axios from 'axios';
import { setAmount, setCategory, setDollar, setImageUrl, setName } from './redux/user';
import { getEthPriceNow } from "get-eth-price";

function App() {
  const dispatch = useDispatch();
  const { wallet_address } = useSelector(getIMXState);

  useEffect(() => {
    const link = new Link(process.env.REACT_APP_LINK_ADDRESS);
    dispatch(setLink(link));

    const client_provider = new AlchemyProvider(process.env.REACT_APP_ETH_NETWORK, process.env.REACT_APP_ALCHEMY_API_KEY);

    ImmutableXClient.build({
      publicApiUrl: process.env.REACT_APP_PUBLIC_API_URL,
      starkContractAddress: process.env.REACT_APP_STARK_CONTRACT_ADDRESS,
      registrationContractAddress: process.env.REACT_APP_REGISTRATION_ADDRESS,
      signer: new Wallet(process.env.REACT_APP_MINTER_PRIVATE_KEY).connect(client_provider)
    }).then((client) => {
      dispatch(setClient(client));
    }).catch((error) => {
      console.log(error);
    });

    axios
      .get(`${process.env.REACT_APP_BACKEND_API_ADDRESS}/category`)
      .then((response) => {
        if (response.data.state === "success") {
          dispatch(setCategory(response.data.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (wallet_address) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_API_ADDRESS}/user/${wallet_address}`)
        .then((response) => {
          if (response.data.state === "success") {
            dispatch(setName(response.data.data?.name));
            dispatch(setImageUrl(response.data.data?.image_url));
            dispatch(setAmount(response.data.data?.amount));
          }
        })
        .catch((error) => {
          console.log(error);
        });

      getEthPriceNow()
        .then(data => {
          for (let key in data) {
            dispatch(setDollar(data[key].ETH.USD));
          }
        });
    }
  }, [dispatch, wallet_address])

  return (
    <Routes >
      {
        routes.map((data, index) => (
          <Route
            onUpdate={() => window.scrollTo(0, 0)}
            exact={true}
            path={data.path}
            element={
              <CheckLogin children={data.component} />
            } key={index} />
        ))
      }
    </Routes>
  );
}

export default App;
