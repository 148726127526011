const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
    },
    {
        id: 2,
        name: 'Explore',
        links: '/collections',
    },
    {
        id: 3,
        name: 'Create',
        links: '/asset/create',
    },
]

export default menus;