import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  link: null,
  client: null,
  wallet_address: localStorage.getItem(process.env.REACT_APP_WALLET_ADDRESS),
  stark_public_key: localStorage.getItem(process.env.REACT_APP_STARK_PUBLIC_KEY),
}

const imx = createSlice({
  name: 'imx',
  initialState,
  reducers: {
    setLink: (state, action) => {
      state.link = action.payload;
    },
    setClient: (state, action) => {
      state.client = action.payload;
    },
    setWalletAddress: (state, action) => {
      state.wallet_address = action.payload;
    },
    setStarkPublicKey: (state, action) => {
      state.stark_public_key = action.payload;
    },
  }
})

export const {
  setLink,
  setClient,
  setWalletAddress,
  setStarkPublicKey,
} = imx.actions

export default imx.reducer