import React from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

export const ModalDialog = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <div className="modal-body space-y-20 pd-40">
        <h3>{props.title}</h3>
        <div className="hr"></div>
        <div className="d-flex justify-content-between">
          <p> {props.description}</p>
        </div>
        <Link
          to={props.url ? props.url : "#"}
          onClick={() => props.setModalDialogShow(false)}
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#popup_bid_success"
          data-dismiss="modal"
          aria-label="Close"
        >
          {" "}
          OK
        </Link>
      </div>
    </Modal>
  );
};
