import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import menus from "../../pages/menu";
import DarkMode from "./DarkMode";
import logoheader from "../../assets/images/logo/logo.png";
import logoheader2x from "../../assets/images/logo/logo@2x.png";
import logodark from "../../assets/images/logo/logo_dark.png";
import logodark2x from "../../assets/images/logo/logo_dark@2x.png";
import avt from "../../assets/images/avatar/avt-2.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  getIMXState,
  getUserState,
  setStarkPublicKey,
  setWalletAddress,
} from "../../redux";
import "./index.css";
import { ethers } from "ethers";
import { WithdrawalModal, DepositModal } from "..";

export const Header = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { wallet_address, client } = useSelector(getIMXState);
  const { image_url } = useSelector(getUserState);
  const [balance, setBalance] = useState(0.0);
  const [modalShow, setModalShow] = useState(false);
  const [withdrawModalShow, setWithdrawModalShow] = useState(false);

  const headerRef = useRef(null);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);
  const isSticky = (e) => {
    const header = document.querySelector(".js-header");
    const scrollTop = window.scrollY;
    scrollTop >= 300
      ? header.classList.add("is-fixed")
      : header.classList.remove("is-fixed");
    scrollTop >= 400
      ? header.classList.add("is-small")
      : header.classList.remove("is-small");
  };

  const menuLeft = useRef(null);
  const btnToggle = useRef(null);

  const menuToggle = () => {
    menuLeft.current.classList.toggle("active");
    btnToggle.current.classList.toggle("active");
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleOnClick = (index) => {
    setActiveIndex(index);
  };

  const disconnectWallet = () => {
    localStorage.removeItem(process.env.REACT_APP_WALLET_ADDRESS);
    localStorage.removeItem(process.env.REACT_APP_STARK_PUBLIC_KEY);

    dispatch(setWalletAddress(null));
    dispatch(setStarkPublicKey(null));
  };

  useEffect(() => {
    if (wallet_address) {
      client
        ?.listBalances({ user: wallet_address })
        .then((balances) => {
          setBalance(ethers.utils.formatEther(balances.result[0].balance));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [client, wallet_address]);

  return (
    <header id="header_main" className="header_1 js-header" ref={headerRef}>
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div id="site-header-inner">
              <div className="wrap-box flex">
                <div id="site-logo" className="clearfix">
                  <div id="site-logo-inner">
                    <Link to="/" rel="home" className="main-logo">
                      <img
                        className="logo-dark logo-img"
                        id="logo_header"
                        src={logodark}
                        srcSet={`${logodark2x}`}
                        alt=""
                      />
                      <img
                        className="logo-light logo-img"
                        id="logo_header"
                        src={logoheader}
                        srcSet={`${logoheader2x}`}
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
                <div
                  className="mobile-button"
                  ref={btnToggle}
                  onClick={menuToggle}
                >
                  <span></span>
                </div>
                <nav id="main-nav" className="main-nav" ref={menuLeft}>
                  <ul id="menu-primary-menu" className="menu">
                    {menus.map((data, index) => (
                      <li
                        key={index}
                        onClick={() => handleOnClick(index)}
                        className={`menu-item ${
                          data.namesub ? "menu-item-has-children" : ""
                        } ${activeIndex === index ? "active" : ""} `}
                      >
                        <Link to={data.links}>{data.name}</Link>
                        {data.namesub && (
                          <ul className="sub-menu">
                            {data.namesub.map((submenu) => (
                              <li
                                key={submenu.id}
                                className={
                                  pathname === submenu.links
                                    ? "menu-item current-item"
                                    : "menu-item"
                                }
                              >
                                <Link to={submenu.links}>{submenu.sub}</Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </nav>
                <div className="flat-search-btn flex">
                  <div className="header-search flat-show-search" id="s1"></div>
                  {!wallet_address ? (
                    <div className="sc-btn-top mg-r-12" id="site-header">
                      <Link
                        to="/wallet-connect"
                        className="sc-button header-slider style style-1 wallet fl-button pri-1"
                      >
                        <span>Wallet connect</span>
                      </Link>
                    </div>
                  ) : (
                    <div className="connect-wal">
                      <div className="admin_active" id="header_admin">
                        <div className="header_avatar">
                          <Link
                            className="price"
                            to="#"
                            onClick={() => setModalShow(true)}
                          >
                            <span>{balance} ETH</span>
                          </Link>
                          <img
                            className="avatar"
                            src={image_url ? image_url : avt}
                            alt="avatar"
                          />
                          <div className="avatar_popup mt-20">
                            <div className="links">
                              <Link to="/my-items" className="">
                                <i className="fab fa-accusoft"></i>{" "}
                                <span> My items</span>
                              </Link>
                              <Link className="mt-30" to="/collections/yours">
                                <i className="far fa-album-collection"></i>{" "}
                                <span> My Collections</span>
                              </Link>
                              <Link className="mt-30" to="/my-favourite">
                                <i className="fas fa-heart"></i>{" "}
                                <span> My Favourite</span>
                              </Link>
                              <Link className="mt-30" to="/edit-profile">
                                <i className="fas fa-pencil-alt"></i>{" "}
                                <span> Edit Profile</span>
                              </Link>
                              <Link
                                className="mt-30"
                                to="#"
                                onClick={() => setModalShow(true)}
                              >
                                <i className="fab fa-google-wallet"></i>
                                <span>Deposit Crypto</span>
                              </Link>
                              <Link
                                className="mt-30"
                                to="#"
                                onClick={() => setWithdrawModalShow(true)}
                              >
                                <i className="fas fa-badge-dollar"></i>
                                <span>Withdraw Crypto</span>
                              </Link>
                              <Link
                                className="mt-30"
                                to="#"
                                id="logout"
                                onClick={disconnectWallet}
                              >
                                <i className="fal fa-sign-out"></i>{" "}
                                <span> Logout</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DepositModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setModalShow={setModalShow}
      />
      <WithdrawalModal
        show={withdrawModalShow}
        onHide={() => setWithdrawModalShow(false)}
        setWithdrawModalShow={setWithdrawModalShow}
      />
      <DarkMode />
    </header>
  );
};
