import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Header, Footer, CollectionCard } from "../../components";
import "./index.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { getIMXState, getUserState } from "../../redux";
import { useLocation } from "react-router";

export const ExploreCollections = () => {
  const [visible, setVisible] = useState(6);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 3);
  };

  const { wallet_address } = useSelector(getIMXState);
  const { category } = useSelector(getUserState);

  const [collections, setCollections] = useState([]);
  const [collection_users, setCollectionUsers] = useState([]);
  const location = useLocation();

  const [currentCategory, setCurrentCategory] = useState("All");

  useEffect(() => {
    if (location.pathname === "/collections/yours") {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_API_ADDRESS}/collections/${wallet_address}`
        )
        .then((response) => {
          if (response.data.state === "success") {
            setCollections(response.data.data);
            setCollectionUsers(response.data.users);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .get(`${process.env.REACT_APP_BACKEND_API_ADDRESS}/collections`)
        .then((response) => {
          if (response.data.state === "success") {
            setCollections(response.data.data);
            setCollectionUsers(response.data.users);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [location.pathname, wallet_address]);
  return (
    <div className="authors-2">
      <Header />
      <section className="flat-title-page inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12">
                <h1 className="heading text-center">
                  {location.pathname === "/collections/yours"
                    ? "My Collections"
                    : "Explore"}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tf-section our-creater dark-style2">
        <div className="themesflat-container">
          <div className="row">
            {location.pathname === "/collections/yours" ? (
              <div className="col-md-12 justify-content-center flex">
                <Link
                  to="/collection/create"
                  className="sc-button white-button style-1"
                >
                  <span>Create Collection</span>
                </Link>
              </div>
            ) : (
              <div className="col-md-12">
                <div className="wrap-box explore-1 flex mg-bt-40">
                  <div className="seclect-box style-1">
                    <div id="item_category" className="dropdown">
                      <Link to="#" className="btn-selector nolink">
                        {currentCategory}
                      </Link>
                      <ul>
                        <li onClick={(e) => setCurrentCategory("All")}>All</li>
                        {category?.map((item, index) => {
                          return (
                            <li
                              key={index}
                              onClick={(e) => setCurrentCategory(item.name)}
                            >
                              <span>{item.name}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {collections
              .map((item, index) =>
                currentCategory === "All" ||
                item.category === currentCategory ? (
                  <CollectionCard
                    key={index}
                    user={collection_users[index]}
                    item={item}
                  />
                ) : (
                  ""
                )
              )
              .slice(0, visible)}
            {visible < collections.length && (
              <div className="col-md-12 wrap-inner load-more text-center">
                <Link
                  to="#"
                  id="load-more"
                  className="sc-button loadmore fl-button pri-3"
                  onClick={showMoreItems}
                >
                  <span>Load More</span>
                </Link>
              </div>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
