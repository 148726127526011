import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: null,
  image_url: null,
  category: null,
  amount: 0.0,
  dollar: 0.0,
}

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setImageUrl: (state, action) => {
      state.image_url = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setAmount: (state, action) => {
      state.amount = action.payload;
    },
    setDollar: (state, action) => {
      state.dollar = action.payload;
    }
  }
})

export const {
  setName,
  setImageUrl,
  setCategory,
  setAmount,
  setDollar
} = user.actions

export default user.reducer