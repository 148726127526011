import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Header, Footer, NFTCard, DeleteModal } from "../../components";
import { useParams } from "react-router";
import axios from "axios";
import "./index.css";
import { useSelector } from "react-redux";
import { getIMXState } from "../../redux";

export const ExploreNFT = () => {
  const [menuTab] = useState([
    {
      class: "active",
      name: "All",
    },
    {
      class: "",
      name: "Selling",
    },
    {
      class: "",
      name: "Not Selling",
    },
  ]);

  const [visible, setVisible] = useState(8);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 4);
  };
  const params = useParams();

  const [nft_data, setNFTData] = useState([]);
  const [collection_data, setCollectionData] = useState();
  const [collection_owner, setCollectionOwner] = useState();
  const { wallet_address } = useSelector(getIMXState);
  const [deleteModalDialogShow, setDeleteModalDialogShow] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API_ADDRESS}/collection/${params.collection_name}`
      )
      .then((response) => {
        if (response.data.state === "success") {
          setNFTData(response.data.data);
          setCollectionData(response.data.collection);
          setCollectionOwner(response.data.user);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [params.collection_name]);

  const deleteCollection = () => {
    setDeleteModalDialogShow(true);
    setDeleteModalData({
      ...deleteModalData,
      title: "Be careful",
      description: "Are you sure to delete this collection?",
      url: `${
        process.env.REACT_APP_BACKEND_API_ADDRESS
      }/collection/${collection_data?.name.toLowerCase()}/delete`,
    });
  };

  return (
    <div className="authors-2">
      <Header />
      <section className="tf-section authors">
        <div className="themesflat-container mt-50">
          <div className="flat-tabs tab-authors">
            <div className="author-profile flex author-height">
              <div className="feature-profile">
                <img
                  src={collection_data?.image_url}
                  className="avatar logo-imges"
                  alt=""
                />
              </div>
              <div className="infor-profile">
                <span>Collection Profile</span>
                <h2 className="title">{collection_data?.name}</h2>
                <p className="content">{collection_data?.description}</p>
              </div>
              <div className="widget-social style-3 btn-list">
                <div className="btn-profile">
                  {wallet_address === collection_owner?.wallet_address ? (
                    <Link
                      to={`/collection/edit/${collection_data?.name.toLowerCase()}`}
                      className="sc-button style-1 follow"
                    >
                      Edit Collection
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
                <div className="btn-profile">
                  {wallet_address === collection_owner?.wallet_address ? (
                    <Link
                      to="#"
                      className="sc-button style-1 follow"
                      onClick={() => deleteCollection()}
                    >
                      Delete Collection
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
                <div className="btn-profile">
                  {wallet_address === collection_owner?.wallet_address ? (
                    <Link
                      to={`/asset/create/${collection_data?.name.toLowerCase()}`}
                      className="sc-button style-1 follow"
                    >
                      Add NFT
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <Tabs>
              <TabList>
                {menuTab.map((item, index) => (
                  <Tab key={index}>{item.name}</Tab>
                ))}
              </TabList>

              <div className="content-tab">
                <div className="content-inner">
                  <div className="row">
                    {menuTab.map((item, index) => (
                      <TabPanel key={index} className="tabs">
                        {nft_data
                          .map((data, index) =>
                            item.name === "All" ||
                            (item.name === "Selling" && data.selling) ||
                            (item.name === "Not Selling" && !data.selling) ? (
                              <NFTCard
                                key={index}
                                item={data}
                                user={collection_owner}
                                collection={collection_data}
                              />
                            ) : (
                              ""
                            )
                          )
                          .slice(0, visible)}
                        {visible < nft_data.length && (
                          <div className="col-md-12 wrap-inner load-more text-center">
                            <Link
                              to="#"
                              id="load-more"
                              className="sc-button loadmore fl-button pri-3"
                              onClick={showMoreItems}
                            >
                              <span>Load More</span>
                            </Link>
                          </div>
                        )}
                      </TabPanel>
                    ))}
                  </div>
                </div>
              </div>
            </Tabs>
          </div>
        </div>
      </section>
      <DeleteModal
        show={deleteModalDialogShow}
        onHide={() => setDeleteModalDialogShow(false)}
        title={deleteModalData?.title}
        description={deleteModalData?.description}
        url={deleteModalData?.url}
        setModalDialogShow={setDeleteModalDialogShow}
      />
      <Footer />
    </div>
  );
};
