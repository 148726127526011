import React from "react";
import { Link } from "react-router-dom";
import icon1 from "../../assets/images/icon/Wallet.png";
import icon2 from "../../assets/images/icon/Category.png";
import icon3 from "../../assets/images/icon/Image2.png";
import icon4 from "../../assets/images/icon/Bookmark.png";
import { useSelector } from "react-redux";
import { getIMXState } from "../../redux";

export const Create = () => {
  const data = [
    {
      title: "Set Up Your Wallet",
      description:
        "Wallet that is functional for NFT purchasing. You may have a Coinbase account at this point, but very few are actually set up to buy an NFT.",
      icon: icon1,
      colorbg: "icon-color1",
      url: "/wallet-connect",
    },
    {
      title: "Create Your Collection",
      description:
        "Setting up your NFT collection and creating NFTs on NFTs is easy! This guide explains how to set up your first collection",
      icon: icon2,
      colorbg: "icon-color2",
      url: "/collection/create",
    },
    {
      title: "Add Your NFTs",
      description:
        "Sed ut perspiciatis un de omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.",
      icon: icon3,
      colorbg: "icon-color3",
      url: "/asset/create",
    },
    {
      title: "List Them For Sale",
      description:
        "Choose fixed-price listings. You choose how you want to sell your NFTs!",
      icon: icon4,
      colorbg: "icon-color4",
      url: "/my-items",
    },
  ];
  return (
    <section className="tf-box-icon create style1 tf-section">
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div className="heading-live-auctions mg-bt-22">
              <h2 className="tf-title pb-17">Create And Sell Your NFTs</h2>
            </div>
          </div>
          {data.map((item, index) => (
            <CreateItem key={index} item={item} />
          ))}
        </div>
      </div>
    </section>
  );
};

const CreateItem = (props) => {
  const { wallet_address } = useSelector(getIMXState);
  const gotoNextPage = (to) => {
    if (wallet_address) {
    } else {
    }
  };
  return (
    <div className="col-lg-3 col-md-6 col-12">
      <div className="sc-box-icon">
        <div className="image">
          <div className={`icon-create ${props.item.colorbg}`}>
            <img src={props.item.icon} alt="" />
          </div>
        </div>
        <h3 className="heading">
          <Link
            to={props.item.url}
            onClick={() => {
              gotoNextPage(props.item.to);
            }}
          >
            {props.item.title}
          </Link>
        </h3>
        <p className="content">{props.item.description}</p>
      </div>
    </div>
  );
};
