import S3 from 'aws-s3';

const config = {
    bucketName: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    dirName: 'nft',
    region: process.env.REACT_APP_AWS_S3_REGION,
    accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_KEY,
    s3Url: process.env.REACT_APP_AWS_S3_URL
}

const s3Client = new S3(config);

export default s3Client;