import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Header, Footer, CardModal, NFTCard } from "../../components";
import avt from "../../assets/images/avatar/avt-2.jpg";
import { useParams } from "react-router";
import axios from "axios";
import "./index.css";
import { useSelector } from "react-redux";
import { getIMXState, getUserState } from "../../redux";

export const ExploreMyItems = () => {
  const [menuTab] = useState([
    {
      class: "active",
      name: "All",
    },
    {
      class: "",
      name: "Collected",
    },
    {
      class: "",
      name: "Created",
    },
  ]);

  const [visible, setVisible] = useState(8);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 4);
  };

  const [modalShow, setModalShow] = useState(false);
  const params = useParams();

  const [nft_data, setNFTData] = useState([]);
  const { wallet_address } = useSelector(getIMXState);
  const { image_url, name } = useSelector(getUserState);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API_ADDRESS}/assets/${wallet_address}`
      )
      .then((response) => {
        if (response.data.state === "success") {
          setNFTData(response.data.data);
          setUsers(response.data.users);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [params.collection_name, wallet_address]);

  return (
    <div className="authors-2">
      <Header />
      <section className="tf-section authors">
        <div className="themesflat-container mt-50">
          <div className="flat-tabs tab-authors">
            <div className="author-profile flex author-height">
              <div className="feature-profile">
                <img
                  src={image_url ? image_url : avt}
                  className="avatar logo-imges"
                  alt=""
                />
              </div>
              <div className="infor-profile">
                <span>Your Profile</span>
                <h2 className="title">{name ? name : "Unnamed"}</h2>
              </div>
            </div>
            <Tabs>
              <TabList>
                {menuTab.map((item, index) => (
                  <Tab key={index}>{item.name}</Tab>
                ))}
              </TabList>

              <div className="content-tab">
                <div className="content-inner">
                  <div className="row">
                    {menuTab.map((item, index) => (
                      <TabPanel key={index} className="tabs">
                        {nft_data
                          .map((data, index) =>
                            item.name === "All" ||
                            (item.name === "Collected" &&
                              data.creator !== data.owner) ||
                            (item.name === "Created" &&
                              data.creator === data.owner) ? (
                              <NFTCard
                                item={data}
                                key={index}
                                user={users[index]}
                              />
                            ) : (
                              ""
                            )
                          )
                          .slice(0, visible)}
                        {visible < nft_data.length && (
                          <div className="col-md-12 wrap-inner load-more text-center">
                            <Link
                              to="#"
                              id="load-more"
                              className="sc-button loadmore fl-button pri-3"
                              onClick={showMoreItems}
                            >
                              <span>Load More</span>
                            </Link>
                          </div>
                        )}
                      </TabPanel>
                    ))}
                  </div>
                </div>
              </div>
            </Tabs>
          </div>
        </div>
      </section>
      <CardModal show={modalShow} onHide={() => setModalShow(false)} />
      <Footer />
    </div>
  );
};
