import { configureStore } from "@reduxjs/toolkit";
import imx from "../imx";
import user from "../user";

export const store = configureStore({
  reducer: {
    imx: imx,
    user: user
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  })
})

export const getIMXState = (state) => state.imx;
export const getUserState = (state) => state.user;