import React from "react";
import { Link } from "react-router-dom";
import { CollectionCard } from "..";

export const PopularCollection = (props) => {
  const data = props.data;
  const users = props.users;
  return (
    <section className="tf-section popular-collection">
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div className="heading-live-auctions">
              <h2 className="tf-title pb-22 text-left">Popular Collection</h2>
              <Link to="/collections" className="exp style2">
                EXPLORE MORE
              </Link>
            </div>
          </div>
          {data.map((item, index) =>
            index < 3 ? (
              <CollectionCard user={users[index]} item={item} key={index} />
            ) : (
              ""
            )
          )}
        </div>
      </div>
    </section>
  );
};
